import store from '@/store';
import { setAutoLoader, setLoader } from '@/store/app/reducer';
// import { resetUserContext } from '@/store/auth/reducer';
import axios, { Axios, AxiosResponse, ResponseType } from 'axios';

class HttpClient {
	private axiosInstance: Axios;

	constructor(apiVersion: string | undefined) {
		this.axiosInstance = new axios.Axios({
			baseURL: process.env.REACT_APP_API_BASE_URL + '/' + apiVersion
		});

		this.axiosInstance.interceptors.request.use((request) => {
			const token = localStorage.getItem('access_token');
			const moduleName = localStorage.getItem('moduleName');
			const entityId = localStorage.getItem('entityId');
			request.headers = {
				...request.headers,
				Authorization: `Bearer ${token}`,
				'content-type': 'application/json',
				'moduleName': moduleName ?? '',
				'logGroupId': entityId ?? ''
			} as any;
			if (!request.headers['X-Hide-Loading-Screen']) {
				store.dispatch(setLoader(true));
			} else {
				store.dispatch(setAutoLoader(true));
			}
			return request;
		},
		undefined,
		{ synchronous: true }
		);

		this.axiosInstance.interceptors.response.use((response) => {
			store.dispatch(setLoader(false));
			store.dispatch(setAutoLoader(false));
			if (response.status >= 400) {
				// store.dispatch(showNotification({
				//   showNotification: true,
				//   message: response.statusText,
				//   type: 'error'
				// }));

				if (response.status === 401) {
					// store.dispatch(showNotification({
					// 	showNotification: true,
					// 	message: response.statusText,
					// 	type: 'error'
					// }));
					// store.dispatch(resetUserContext());
					localStorage.removeItem('access_token');
				} else if (response.status === 595) {
					// store.dispatch(showNotification({
					// 	showNotification: true,
					// 	message: 'You have been logged off because either your profile or your organization\'s settings have been updated',
					// 	type: 'warning'
					// }));
					// store.dispatch(resetUserContext());
					localStorage.removeItem('access_token');
				}
				return response;
			} else {
				try {
					const data = JSON.parse(response.data);
					return { ...response, data };
				} catch (error) {
					return response;
				}
			}
		},
		() => {
			store.dispatch(setLoader(false));
			// store.dispatch(showNotification({
			//   showNotification: true,
			//   message: err.message,
			//   type: 'error'
			// }));
		},
		{ synchronous: true }
		);
	}


	get<T>(endpoint: string, params?: Record<string, any>) {
		return this.axiosInstance.get<T>(endpoint, {
			params
		});
	}

	patch<T>(endpoint: string, params?: Record<string, any>): Promise<T> {
		return this.axiosInstance.patch<any, T>(endpoint, {
			params
		});
	}

	getQRCode<T>(endpoint: string, params?: Record<string, any>, headers: any = {}, responseType: ResponseType = 'json'): Promise<T> {
		return this.axiosInstance.get<any, T>(endpoint, {
			responseType: responseType,
			params,
			headers
		});
	}
	getImage<T>(endpoint: string, params?: Record<string, any>, headers: any = {}, responseType: ResponseType = 'blob'): Promise<T> {
		return this.axiosInstance.get<any, T>(endpoint, {
			responseType: responseType,
			params,
			headers
		});
	}

	uploadFile<T>(endpoint: string, body: any, { params, headers = {} }: { params?: Record<string, any>, headers: Record<string, any> }) {
		return this.axiosInstance.post<T>(endpoint, body, {
			params,
			headers
		});
	}

	postFormData<T>(endpoint: string, body: any, params?: Record<string, any>) {
		return this.axiosInstance.post<T>(endpoint, body, {
			params,
			headers: {'Content-Type': 'multipart/form-data'}
		});
	}

	post<T>(endpoint: string, body?: any, params?: Record<string, any>): Promise<AxiosResponse<T, any>> {
		return this.axiosInstance.post<T>(endpoint, JSON.stringify(body), {
			params,
		});
	}

	delete<T>(endpoint: string,params?: Record<string, any>):Promise<AxiosResponse<T, any>> {
		return this.axiosInstance.delete<T>(endpoint, {
			params,
		});
	}

	deleteV2<T> (endpoint: string, { params, headers = {}, body}: { params?: Record<string, any>, headers: Record<string, any>, body?: Record<string, any> }) {
		return this.axiosInstance.delete<T>(endpoint, {
			params,
			headers,
			data: JSON.stringify(body)
		});
	}

	put<T>(endpoint: string, body?: any, params?: Record<string, any>): Promise<AxiosResponse<T, any>> {
		return this.axiosInstance.put<T>(endpoint, JSON.stringify(body), {
			params,
		});
	}
}

export const httpClient = new HttpClient(process.env.REACT_APP_API_VERSION_V1);
export const httpClientV2 = new HttpClient('client');
