import { httpClient } from './httpClient';

export const uploadFile = async (payload: File) => {
	const formData = new FormData();
	formData.append('file', payload);

	return httpClient.uploadFile<string>('/Blob/Nabu/UploadFile', formData, {
		headers: {
			'Content-Type': 'multipart/form-data; boundary=AaB03x' +
			'--AaB03x' +
			'Content-Disposition: file' +
			'Content-Transfer-Encoding: binary' +
			'...data... ' +
			'--AaB03x--',
			'Accept': 'application/json',
			'type': 'formData'
		},
	});
};

export const uploadFiles = async (files: File[]) => {
	const formData = new FormData();
	for (let index = 0; index < files.length; index++) {
		formData.append('files', files[index]);
	}

	return httpClient.uploadFile<any[]>('/Blob/Nabu/UploadFiles', formData, {
		headers: {
			'Content-Type': 'multipart/form-data; boundary=AaB03x' +
			'--AaB03x' +
			'Content-Disposition: file' +
			'Content-Transfer-Encoding: binary' +
			'...data... ' +
			'--AaB03x--',
			'Accept': 'application/json',
			'type': 'formData'
		},
	});
};

export const setModuleName = (moduleName: string) => {
	localStorage.setItem('moduleName', moduleName);
};

export const setEntityId = (entityId: string) => {
	localStorage.setItem('entityId', entityId);
};