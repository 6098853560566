import { Suspense, lazy } from 'react';
import { Navigate, Outlet, createHashRouter, useLocation } from 'react-router-dom';
import { Loader } from './common/components/atoms/loader/loader';
import { ReportType } from './common/enums';
const ErrorPage = lazy(() => import( './common/components/templates/pages/error/errorPage'));
const ActiveAssetsReport =  lazy(() => import('./modules/reports/activeAssetsReport'));
const ExpiredAssetsReport =  lazy(() => import('./modules/reports/expiredAssetsReport'));
const EarliestExpirationAssetReport =  lazy(() => import('./modules/reports/earliestExpirationAssetReport'));
const CastExpirationReport =  lazy(() => import('./modules/reports/castExpirationReport'));
const UseRightsExpireReport =  lazy(() => import('./modules/reports/useRightsExpireReport'));
const ActiveElementsReport =  lazy(() => import('./modules/reports/activeElementsReport'));
const ExpiredElementsReport =  lazy(() => import('./modules/reports/expiredElementsReport'));
const GuaranteeExpireReport =  lazy(() => import('./modules/reports/guaranteeExpireReport'));

const WrapLoader = (Component: React.ReactNode) => {
	return (
		<Suspense fallback={<Loader showLoader={true}/>}>
			{Component}
		</Suspense>
	);
};

const BeforeLoad = () => {
	const location = useLocation();
	const token = new URLSearchParams(location.search).get('token');
	localStorage.setItem('access_token', token || '');
	localStorage.setItem('moduleName', 'Reports');
	
	return <Outlet />;
};

const router = createHashRouter([
	{
		element: <BeforeLoad />,
		errorElement: <ErrorPage />,
		children: [
			{ 
				path: '/', 
				element: <Navigate to={`${ReportType.ACTIVE_ASSET}`} replace />,
			},
			{
				path: `${ReportType.ACTIVE_ASSET}`,
				element: WrapLoader(<ActiveAssetsReport />)
			},
			{
				path: `${ReportType.ASSET_EXPIRATION}`,
				element: WrapLoader(<ExpiredAssetsReport />)
			},
			{
				path: `${ReportType.ASSET_EARLIEST_EXPIRATION}`,
				element: WrapLoader(<EarliestExpirationAssetReport />)
			},
			{
				path: `${ReportType.CAST_EXPIRATION}`,
				element: WrapLoader(<CastExpirationReport />)
			},
			{
				path: `${ReportType.NEXT_HOLDING_FEE_DUE}`,
				element: WrapLoader(<UseRightsExpireReport key='holding-fee-report' isHoldingFee={true} />)
			},
			{
				path: `${ReportType.USE_RIGHTS_EXPIRING}`,
				element: WrapLoader(<UseRightsExpireReport key='use-right-fee-report'/>)
			},
			{
				path: `${ReportType.ACTIVE_ELEMENTS}`,
				element: WrapLoader(<ActiveElementsReport />)
			},
			{
				path: `${ReportType.ELEMENT_EXPIRATION}`,
				element: WrapLoader(<ExpiredElementsReport />)
			},
			{
				path: `${ReportType.GUARANTEE_EXPIRING}`,
				element: WrapLoader(<GuaranteeExpireReport />)
			}
		],
	},
]);

export default router;
