class ReportLicense {
	private licenseKey;

	constructor(){
		this.licenseKey = process.env.REACT_APP_REPORT_LICENSE_KEY;
	}

	getLicenseKey(){
		return this.licenseKey;
	}
}

export const reportLicense = new ReportLicense();
