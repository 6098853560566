import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './modules/app/app';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';

import './main.module.scss';
import { NotificationContextProvider } from './common/utils/notificationService';
import { LoaderContextProvider } from './common/utils/loaderService';

import '@grapecity/activereports/styles/ar-js-ui.css';
import '@grapecity/activereports/styles/ar-js-designer.css';
import '@grapecity/activereports/styles/ar-js-viewer.css';

import { Core } from '@grapecity/activereports';
import { reportLicense } from './services/reportLicense';

Core.PageReport.LicenseKey = reportLicense.getLicenseKey();

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<LoaderContextProvider>
					<NotificationContextProvider>
						<App />
					</NotificationContextProvider>
				</LoaderContextProvider>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>
);

reportWebVitals();
